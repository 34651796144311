import React, {useState, useEffect} from 'react';
import { useLocation} from 'react-router-dom';
import { useUserContext } from "../../contexts";
import { getProducts, Analytics } from '../../lib/api';
import { Header } from '../Header';
import { Faq } from '../Faq';
import { Spinner } from '../Spinner';
import { Footer } from '../Footer';
import productsMetadata from '../../constants/plans';

import './Plans.v2.scss';

const PlansV2 = ({donator=true }) => {

  //Custom Hooks
  const {isAuthenticating, user, setUserToken} = useUserContext();
  
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  //State to control the loading spinner
  const [showLoading, setShowLoading] = useState(false);
 
  //State to control the payment provider
  const [provider, setProvider] = useState(query.get('prov') && query.get('prov') === 'p'?'paypal':'stripe');

  //State to control/filter what plans to show Month/Year (for the moment)
  const [planPeriod, setPlanPeriod] = useState(query.get('freq') && query.get('freq') === 'y'?'Year':'Month');

  //Used to control the donator/free toggle switch
  const [donatorCheck, setDonatorCheck] = useState(donator);

  //This is the list of products that will be shown in the plans section
  const [products, setProducts] = useState([]);


  const searchPlan = plan_id => {
    let plan = null;
    console.log(products);
    Object.keys(products).map(k => products[k]).forEach(prod => {
        if(plan){
            return;
        }
        plan = prod.plans.find(p => +p.id === +plan_id);
    });
    return plan;
  };
  
  const filterProducts = (data) => {

    let newProducts = {};

    data.forEach((prod, i) => {
        newProducts[prod.name.toLowerCase()] = {
            ...prod,
            metadata: productsMetadata.find(pm => pm.name.toLowerCase() === prod.name.toLowerCase()),
            annual: prod.plans.filter(p => p.periodicity_type.toLowerCase() === 'year' && p.name.toLowerCase() !== 'free').pop(),
            monthly: prod.plans.filter(p => p.periodicity_type.toLowerCase() === 'month' && p.name.toLowerCase() !== 'free').shift(),
            free: prod.plans.filter(p => p.name.toLowerCase() === 'free').pop(),
        };
    });
    
    return newProducts;
  };

  //USeEffects listeners
  useEffect(() => {
    //getProducts({campaign}).then(data => console.log("By API DATA", data));
    let campaign = null;

    getProducts({campaign}).then(data => {
     setProducts(filterProducts(data));
    })
    .catch(e => {
        console.log("Problem while getting products", e, {campaign});
    });
  }, []);

  useEffect(() => {
    console.log("PRODUCTS TO SHOW", products);
    if (Object.keys(products).length>0) {
        setShowLoading(false);
    }
  }, [products]);
  useEffect(() => {
    if(isAuthenticating){
        return;
    }
    if (user && user.team) {
        //console.log("USERRRR", user);
        document.location.href = '/upgrade';
        return;
    }
  }, [isAuthenticating, user]);

  const handleCta = plan_id =>{
    console.log("selected plan", plan_id);
    //setShowLoading(true);
    try{
        const plan = searchPlan(plan_id);
        console.log("plan", plan);
        Analytics.addToCart(plan.price);
    }catch(e){
        console.log("analytics error", e);
    }
    setTimeout(() => document.location.href=`/signup/${plan_id}/${provider==='stripe'?'s':'p'}`, 300);
    
  };
  return (
    <>
        <Header minimal={true} />
        {!showLoading && 
        <div className={`plans_v2`} >
            <div className="title m-b-48 xs:m-b-24">Our plans</div>
            <div className="switch m-b-40" id="plan-period-switch">
                <div className="option" onClick={() => setPlanPeriod('Month')}>Monthly</div>
                <div className={`bar ${planPeriod=='Month'?'left':'right'}`} onClick={() => setPlanPeriod(planPeriod=='Month'?'Year':'Month')}>
                    <div className="circle"></div>
                </div>
                <div className="option"  onClick={() => setPlanPeriod('Year')}>Yearly</div>
            </div>
            <div className={`list ${Object.keys(products).length>0?'visible':''} ${planPeriod == 'Year'?'summarized':''}`}>
            {products.pro && products.pro.annual && products.pro.monthly && 
            <div className={`plan ${products.pro.metadata.accent?'accent':''} super_accent`} style={{zIndex:'3', marginRight: `${planPeriod == 'Year'?'calc(-335px - 24px':''}`}} >
                <div className="name m-b-24 xs:m-b-0">Annual PRO</div>
                {planPeriod==='Year' &&
                    <div className="old-price">${products.pro.monthly.price}/mo</div>
                }
                <div className="price">
                    $
                    <div className="accent">
                        {Math.ceil(products.pro.annual.price/12)}
                    </div>
                    /mo
                </div>
                <div className="note ">billed ${products.pro.annual.price} annually</div>
                <div className="cta m-t-24 m-b-48 xs:m-t-16 xs:m-b-16" onClick={() => handleCta(products.pro.annual.id)}>Get Started</div>
                <div className="features">
                    {products.pro.metadata.features && products.pro.metadata.features.map((feature, feature_index) => (
                    <div className="item" key={`product-feature-${feature_index}`}>
                        {feature.variable && 
                            <div style={{display: 'inline-flex', gap: '4px'}}>
                                {feature.donatorValue}
                                {feature.name}
                            </div>
                        }
                        {!feature.variable && 
                            <>
                            {feature.link && 
                            <a href={feature.link} target="_blank">{feature.name}</a>   
                            }
                            {!feature.link &&
                                <>
                                    {feature.name}
                                </>
                            }
                            </>
                        }
                        {feature.tooltip && 
                            <div className="tooltip">
                                {feature.tooltip}
                            </div>
                        }
                    </div> 
                    ))}
                </div>
            </div>
            }
            {Object.keys(products).map(k => products[k]).map((product, product_index) => (
                <div className={`plan ${product.metadata && product.metadata.accent?'accent':''} ${planPeriod == 'Year' && product.metadata.name.toLowerCase() === 'pro'?'hidden':''}`} key={product_index} style={{order: `${product.metadata.order}`}}
                >
                    <div className='bg_whiter'></div>
                    {product.metadata.accent &&
                    <div className="subNote">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <path d="M6.19055 10.7957L3.28144 12.6306C3.15292 12.7163 3.01857 12.753 2.87837 12.7407C2.73817 12.7285 2.6155 12.6796 2.51035 12.5939C2.4052 12.5083 2.32342 12.4012 2.265 12.2725C2.20659 12.1438 2.1949 12.0002 2.22995 11.8416L3.00104 8.37359L0.424899 6.04321C0.308067 5.93311 0.235164 5.8076 0.20619 5.66668C0.177216 5.52576 0.185861 5.38826 0.232126 5.25419C0.278859 5.11963 0.348958 5.00953 0.442424 4.9239C0.535889 4.83827 0.664405 4.78322 0.827969 4.75875L4.22778 4.44681L5.54214 1.18062C5.60055 1.03383 5.69121 0.923728 5.81412 0.850331C5.93703 0.776933 6.06251 0.740234 6.19055 0.740234C6.31907 0.740234 6.44455 0.776933 6.56699 0.850331C6.68943 0.923728 6.78009 1.03383 6.83897 1.18062L8.15333 4.44681L11.5531 4.75875C11.7167 4.78322 11.8452 4.83827 11.9387 4.9239C12.0321 5.00953 12.1022 5.11963 12.149 5.25419C12.1957 5.38875 12.2046 5.52649 12.1756 5.66742C12.1466 5.80834 12.0735 5.9336 11.9562 6.04321L9.38007 8.37359L10.1512 11.8416C10.1862 12.0007 10.1745 12.1445 10.1161 12.2732C10.0577 12.4019 9.97591 12.5088 9.87076 12.5939C9.76561 12.6796 9.64294 12.7285 9.50274 12.7407C9.36254 12.753 9.22818 12.7163 9.09967 12.6306L6.19055 10.7957Z" fill="#F4F4F4"/>
                        </svg>
                        MOST POPULAR
                    </div>
                    }
                    <div className="name m-b-24 xs:m-b-0">{product.metadata.name}</div>
                    {planPeriod==='Year' && (!product.free || product.free && donatorCheck) &&
                    <div className="old-price">${product.monthly.price}/mo</div>
                    }
                    {planPeriod==='Year' && product.free && !donatorCheck &&
                    <div className="old-price">-</div>
                    }
                    <div className="price">
                        $
                        <div className="accent">
                            {(!product.free || (product.free && donatorCheck))?
                            `${planPeriod==='Year'?Math.ceil(product.annual.price/12):product.monthly.price}`
                            :
                            '0'
                            }
                        </div>
                        {planPeriod == 'Year'?'/mo':''}
                    </div>
                    {planPeriod==='Year' && (!product.free || (product.free && donatorCheck)) &&
                    <div className="note">billed ${product.annual.price} annually</div>
                    }
                    {planPeriod==='Month' && (!product.free || (product.free && donatorCheck)) &&
                    <div className="note">per month</div>
                    }
                    {!donatorCheck && product.free && 
                    <div className="note">optional billing</div>
                    }
                    {!donatorCheck && product.free && 
                    <div className="cta m-t-24 m-b-48 xs:m-t-16 xs:m-b-16" onClick={() => handleCta(product.free.id)}>Get Started</div>
                    }  
                    {(donatorCheck || !product.free) &&  
                    <div className="cta m-t-24 m-b-48 xs:m-t-16 xs:m-b-16" onClick={() => handleCta(product[planPeriod=='Year'?'annual':'monthly'].id)}>Get Started</div>
                    }
                    <div className="features sm:m-b-12">
                        {product.metadata.features && product.metadata.features.map((feature, feature_index) => (
                        <div className="item" key={`product-${product_index}-feature-${feature_index}`}>
                            {feature.variable && 
                                <div style={{display: 'inline-flex', gap: '4px'}}>
                                    {donatorCheck && <span>{feature.donatorValue} </span>}
                                    {!donatorCheck && <span>{feature.freeValue} </span>}
                                    <span>{feature.name}</span>
                                </div>
                            }
                            {!feature.variable && 
                                <>
                                {feature.link && 
                                <a href={feature.link} target="_blank">{feature.name}</a>   
                                }
                                {!feature.link &&
                                    <>
                                        {feature.name}
                                    </>
                                }
                                </>
                            }
                            {feature.tooltip && 
                                <div className="tooltip">
                                    {feature.tooltip}
                                </div>
                            }
                        </div> 
                        ))}
                    </div>
                    {product.free && 
                    <label className="donate" htmlFor="donate-checkbox">
                        <input type="checkbox" id="donate-checkbox" value={donatorCheck} onChange={() => setDonatorCheck(!donatorCheck)} checked={donatorCheck} />
                        Donate $15/mo for 4x Downloads
                    </label>
                    }
                </div>
            ))}
            {planPeriod==='Year' && false && 
                <div className={`plan accent`} style={{order: '100', opacity: '0'}}>
                </div>
            }
            </div>
            
            <div className="switch m-b-40 m-t-48" id="plan-provider-switch">
                <div className="option" onClick={() => setProvider('paypal')}>Paypal</div>
                <div className={`bar ${provider=='paypal'?'left':'right'}`} onClick={() => setProvider(provider=='paypal'?'stripe':'paypal')}>
                    <div className="circle"></div>
                </div>
                <div className="option"  onClick={() => setProvider('stripe')}>Stripe</div>
            </div>
            <div className="custom-claim">
                <b>Looking for a custom solution?</b> 
                <a href="mailto:support@productioncrate.com">Contact us</a> 
                and we'll build a custom plan for you, tailored to your specific business needs.
            </div>
        </div>
}
        {showLoading && 
            <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', width: '100%', height: 'calc(80vh - 300px)'}}>
                <Spinner style={{position: 'relative'}} />
            </div>
        }
        <Faq />
        <Footer />
    </>
  );
};

export default PlansV2;
