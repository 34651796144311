

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

export const deleteCookie = (name, domain=false) => {
    if(!domain){
        if(getEnvironment() === "development"){
            domain = "localhost";
        }else{
            domain="productioncrate.com";
        }
    }
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/'+(domain ? "; domain="+domain : "");
};

export const setCookie = (name, value, days, domain=false) => {
    if(!domain){
        if(getEnvironment() === "development"){
            domain = "localhost";
        }else{
            domain=".productioncrate.com";
        }
    }
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"+(domain ? "; domain="+domain : "");
};


export const getPersistedValue = (localStorageKey, cookieKey) => {
    let localStorageValue = localStorage.getItem(localStorageKey);
    let cookieValue = getCookie(cookieKey);

    console.debug("get persisted value", localStorageKey, cookieKey, localStorageValue === cookieValue);

    if(cookieValue && cookieValue != localStorageValue){
        console.debug("Persisted values differences (wrong localstorage)");
        setPersistedValue(localStorageKey, cookieKey, cookieValue);
        return cookieValue;
    }

    if(localStorageValue && cookieValue != localStorageValue){
        console.debug("Persisted values differences (wrong/no cookie)");
        setPersistedValue(localStorageKey, cookieKey, localStorageValue);
        return localStorageValue;
    }

    return localStorageValue || cookieValue;
};

export const setPersistedValue = (localStorageKey, cookieKey, value) => {
    if(!value){
        //console.log("Got a falsy value for "+localStorageKey+"/"+cookieKey+" removing...");
        localStorage.removeItem(localStorageKey);
        deleteCookie(cookieKey);
        return;
    }
    console.debug("Persisting value in ",localStorageKey, cookieKey);
    localStorage.setItem(localStorageKey, value);
    setCookie(cookieKey, value, 365);
};


export const getReadableDate = (timestring) => {
    let date = new Date(timestring);

    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
}

export const getEnvironment = () => window.location.hostname === "localhost" ? "development" : window.location.hostname === "user-qa.productioncrate.com" ? "qa" : "production";


