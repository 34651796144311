import React, {useEffect, useState} from 'react';
import { useUserContext } from "../../contexts";
import { getReadableDate } from '../../lib/utils';
import './Membership.scss';


const Membership = ({cta}) => {

    //Custom Hooks
    const {user, isAuthenticating} = useUserContext();

    const [premiumAssetsPolicy, setPremiumAssetsPolicy] = useState(false);
    const [isEnterprise, setIsEnterprise] = useState(false);

    const isMoreThanTwoYears = (expired_at) => {
        // Parsea la fecha 'expired_at' a un objeto Date
        const expiredDate = new Date(expired_at);
    
        // Obtén la fecha actual
        const currentDate = new Date();
    
        // Añade dos años a la fecha actual
        const twoYearsLater = new Date();
        twoYearsLater.setFullYear(currentDate.getFullYear() + 2);
    
        // Compara si 'expired_at' es mayor que la fecha actual más dos años
        return expiredDate > twoYearsLater;
    };

    useEffect(() => {
        //console.log("SETUSER", setUser);
        if(!user && !isAuthenticating){
            return;
        }
           
        if(isAuthenticating){
            return;
        }

        /*
        if(user.team && user.team.subscription && user.team.subscription.plan){
            let tempPlan = user.team.subscription.plan;
            let charges = 0;
            let usersOnTeam = 0;
            tempPlan.features.forEach(feat => {
                if(feat.name === 'assets-download'){
                    charges =+ feat.charges;
                    if (feat.policy && feat.policy.name.toLowerCase().includes('free')) {
                        setPremiumAssetsPolicy(false);
                    } else {
                        setPremiumAssetsPolicy(true);
                    }
                }
                if(feat.name === 'users-on-team'){
                    usersOnTeam += feat.charges;
                    if(usersOnTeam > 1){
                        setIsEnterprise(usersOnTeam);
                    }else{
                        setIsEnterprise(false);
                    }
                }
            });
        }
        */
    }, [user, isAuthenticating]);

    const handleMembershipClick = () => {
        if(cta){
            cta();
        }
    };
    if(!user || !user.team || !user.team.subscription || !user.team.subscription.plan ){
        return null;
    }
    return (
        <div className={`membership m-b-8 ${cta?'cta':''}`} onClick={handleMembershipClick}>
            {user.premiumAssetsPolicy && <span className="glyphicon glyphicon-star star" ></span>}
            {!user.premiumAssetsPolicy && user.team.subscription.plan.price > 0 && <span className="glyphicon glyphicon-star-half star" ></span>}
            {user.premiumAssetsPolicy?"User Level: PRO":`User Level: ${user.team.subscription.plan.price===0?'Sample':'Contributor'}`}
            {user.isEnterprise && <div className="chip accent">enterprise</div>}
            { user.team.subscription.state === 'canceled' && user.team.subscription.expired_at && 
            <>
                <div className="chip canceled">canceled</div>
                { !isMoreThanTwoYears(user.team.subscription.expired_at) && 
                <div className="chip">
                    Access remains active until {getReadableDate(user.team.subscription.expired_at)}
                </div>
                }
            </>
            }
            { user.team.subscription.state === 'pending' && user.team.subscription.expired_at && 
            <>
                <div className="chip">pending</div>
                { !isMoreThanTwoYears(user.team.subscription.expired_at) && 
                <div className="chip">
                    Your membership has a pending payment.
                </div>
                }
            </>
            }
        </div>
    );
};

export default Membership;

