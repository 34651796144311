import React, {useEffect, useState, useImperativeHandle, forwardRef} from 'react';
import md5 from 'js-md5';
import { useUserContext } from "../../contexts";
import { searchAssets, subscribeUserFn, meFn } from '../../lib/api';
import Logo from './logo.svg';
import LogoName from './logo.name.svg';
import LogoIcon from './logo.icon.svg';
import { Spinner } from '../Spinner';
import './Header.scss';



const Header = ({minimal=false, external=false}, ref) => {

    const {user, unloadUser, focusMode} = useUserContext();

    const [searchQuery, setSearchQuery] = useState('');
    //const [user, setUser] = useState(null);
    const [assetsAvailable, setAssetsAvailable] = useState(null);
    const [premiumAssetsPolicy, setPremiumAssetsPolicy] = useState(false);
    
    const [banners, setBanners] = useState([]);
    const [bannerToDisplay, setBannerToDisplay] = useState(null);

    const [loading, setLoading] = useState(false);

    const baseHref = external?'https://user.productioncrate.com':'';

    useImperativeHandle(ref, () => ({
        assignAssetsAvailable(remaining){
            setAssetsAvailable(remaining);
        }
    }));

    useEffect(() => {
        const getEnvironment = () => window.location.hostname === "localhost" ? "development" : window.location.hostname === "user-qa.productioncrate.com" ? "qa" : "production";
        let env = getEnvironment();
        fetch(`https://${env === 'production'?'rest':'rest-qa'}.productioncrate.com/web/banners`)
        .then(response => response.json())
        .then(data => {
            setBanners(data);
        });
    }, []);

    useEffect(() => {
        //console.log("header user", user);
        if(user && user.team && user.team.subscription && user.team.subscription.plan){
            let tempPlan = user.team.subscription.plan;
            tempPlan.features.forEach(feat => {
                if(feat.name === 'assets-download'){
                    if (feat.policy && feat.policy.name.toLowerCase().includes('free')) {
                        setPremiumAssetsPolicy(false);
                    } else {
                        setPremiumAssetsPolicy(true);
                    }
                }
            });
        }
    }, [user]);
    useEffect(() => {
        if(!user || !banners || banners.length === 0){
            return;
        }
        let seenBanners = {};
        try{
            seenBanners = user.settings && user.settings.banners?JSON.parse(user.settings.banners):{};
        }catch(e){
            console.log("Problem parsing banners", e);
        }
        //console.log("user settings banners", user.settings.banners);
        //console.log("seenBanners", seenBanners);
        let newBanners = banners.filter(banner => !seenBanners.hasOwnProperty(+banner.id));
        //console.log("newBanners", newBanners);
        const blacklist = ["register", "upgrade", "login", "signup"];

        if (newBanners.length > 0 && !blacklist.some(substring => window.location.href.includes(substring))) {
            //console.log("Setting banner to display", newBanners[0]);
            setBannerToDisplay(newBanners[0]);
        }
    }, [user, banners]);

    const hideBanner = (id) => {
        setBannerToDisplay(null);
        let seenBanners = {};
        try{
            seenBanners = user.settings && user.settings.banners?JSON.parse(user.settings.banners):{};
        }catch(e){
            console.log("Problem parsing banners", e);
        }
        seenBanners[+id] = true;
        meFn({banners: JSON.stringify(seenBanners)});
    };

    const handleSearch = (event) => {
        event.preventDefault();

        searchAssets(searchQuery);
        setSearchQuery('');
    };

    const handleLogout = () => {
        unloadUser();
    };


    const reactivateUser = (provider, planId) => {
        setLoading(true);
        let postData = {provider, planId};
        
        subscribeUserFn(postData).then(data => {

            if(data.url){
                document.location.href = data.url;
            } else {
                console.log("Problem while subscribing user ie", data);
                document.location.href = '/register';
            }
            setLoading(false);
        })
        .catch(e => {
            console.log("Problem while subscribing user tc", e);
            setLoading(false);
            document.location.href = '/register';
        });

        

    };
    

    return (
        <div className={`header ${focusMode?'fade-out':''}`}>
            {bannerToDisplay &&
            <div className="special_date">
                <span className="content">
                    {bannerToDisplay.url && 
                    <a href="https://user.productioncrate.com/register/black-friday?utm_source=web_banner" target="_blank">
                        {bannerToDisplay.message}
                    </a>
                    }
                    {!bannerToDisplay.url &&
                    <>
                        {bannerToDisplay.message}
                    </>
                    }
                </span>
                <span className="close" onClick={() => hideBanner(bannerToDisplay.id)}>X</span>
            </div>
            }
            <div className="first-row">
                <div className="wrapper">
                    <a className="logo" href="https://www.productioncrate.com">
                        <img className="pic" src={`${baseHref}${Logo}`} alt="Logo" />
                        <div className="name">
                            <img src={`${baseHref}${LogoName}`} alt="logo name" />
                            <div className="brand_subtitle">
                                by ProductionCrate
                            </div>
                        </div>
                    </a>
                    {!minimal && 
                    <form className="search-bar" id="js-search-form" onSubmit={handleSearch} >
                        
                            <input type="text" value={searchQuery} onChange={({target}) => setSearchQuery(target.value)} name="query" placeholder="Try searching &quot;new&quot; to see recent uploads" spellCheck="True" autoComplete="off" dir="auto" />
                            <button type="submit" className="form-btn btn-search">
                                <span className="glyphicon glyphicon-search"></span>
                            </button>
                            <div className="form-btn btn-setting">
                                <span className="glyphicon-settings glyphicon"></span>
                            </div>
                            <div className="submenu">
                                <div className="item checked" data-site="vfx">Search FootageCrate</div>
                                <div className="item checked" data-site="sfx">Search SoundsCrate</div>
                                <div className="item checked" data-site="graphics">Search GraphicsCrate</div>
                                <div className="item checked" data-site="3D">Search RenderCrate</div>
                                <div className="item checked" data-site=".">Search Tutorials</div>
                            </div>
                        
                    </form>
                    }
                    <div className="account-menu">
                        <span className="glyphicon glyphicon-down"></span>
                        {user && 
                            <div className="username" >
                                {user.username?user.username:user.email}
                            </div>
                        }
                        {!user && 
                            <div className="username" >
                                Account
                            </div>
                        }
                        <div className="profile-pic" >
                            {false && <img className="pic" src="https://cdn.staticcrate.com/profile/1487723/200x200/1000000000.png" /> }
                            <img src={`https://www.gravatar.com/avatar/${md5((user?user.email:'').trim().toLowerCase())}?s=200&d=${encodeURIComponent('https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg')}`} />
                        </div>
                        <div className="submenu" id="js-account-menu">
                            {assetsAvailable !== null && user &&
                            <div className="item head">
                                {assetsAvailable} downloads remaining today.
                            </div>
                            }
                            {user && 
                            <a className="item show-logged-free show-logged-pro" href={`${baseHref}/member`}>
                                <i className="glyphicon glyphicon-user"></i>
                                Member Page
                            </a>
                            }
                            {!user && 
                            <>
                                <a className="item show-logged-out" href={`${baseHref}/login`}>
                                    <i className="glyphicon glyphicon-user"></i>
                                    Login
                                </a>
                                <a className="item show-logged-out" href={`${baseHref}/register`}>
                                    <i className="glyphicon glyphicon-lock"></i>
                                    Register
                                </a>
                                {false &&
                                <a className="item show-logged-out" href={`${baseHref}/register`}>
                                    <i className="glyphicon glyphicon-facebook-square"></i>
                                    Facebook Connect
                                </a>
                                }
                                <a className="item show-logged-out" href={`${baseHref}/register`}>
                                    <i className="glyphicon glyphicon-google-plus-square"></i>
                                    Google Connect
                                </a>
                            </>
                            }
                            {!premiumAssetsPolicy &&
                            <>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade/paypal`}>
                                    <i className="glyphicon glyphicon-paypal"></i>
                                    PRO Upgrade (PayPal)
                                </a>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade`}>
                                    <i className="glyphicon glyphicon-credit-card"></i>
                                    PRO Upgrade (credit/Debit)
                                </a>
                            </>
                            }
                            {user &&   
                                <div className="item show-logged-free show-logged-pro" onClick={handleLogout}>
                                    <i className="glyphicon glyphicon-lock"></i>
                                    Logout
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {!minimal && 
            <div className="second-row">
                <div className="wrapper">
                    <div className="menu">
                        <div className="submenu">
                            <img className="crate-logo-pic" src={`${baseHref}${LogoIcon}`} alt="logo icon" />
                            <div className="dropdown">
                                <div className="head">ProductionCrate</div>
                                <div className="menu-col">
                                    <a className="item title" href="https://www.productioncrate.com/search/#!/new">Software & Tools</a>
                                    <a className="item" href="https://www.productioncrate.com/portal">Portal Installer</a>
                                    <a className="item" href="https://www.productioncrate.com/laforge">LaForge</a>
                                    <div className="item with-submenu">
                                        <a href="https://apps.apple.com/app/apple-store/id1575694812?pt=122505945&amp;ct=productioncrate&amp;mt=8" target="_blank">Mobile Apps +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://apps.apple.com/app/apple-store/id1575694812?pt=122505945&amp;ct=productioncrate&amp;mt=8" target="_blank">ProductionCrate AR</a>
                                        </div>
                                    </div>
                                    <div className="item with-submenu" >
                                        <a href="https://news.productioncrate.com/download-productioncrates-free-extension/">Browser Extensions +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://chrome.google.com/webstore/detail/productioncrate-connect/oagbohjaeipmkcfcjkjhnklieidnkpdo" target="_blank">Chrome</a>
                                            <a className="subitem" href="https://addons.opera.com/en/extensions/details/productioncrate-connect/" target="_blank">Opera</a>
                                            <a className="subitem" href="https://addons.mozilla.org/en-US/firefox/addon/productioncrate-connect/" target="_blank">Firefox</a>
                                        </div>
                                    </div>
                                    <div className="item with-submenu">
                                        <a href="https://www.productioncrate.com/news/scripts-and-plugins/">After Effects +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem title" href="https://www.productioncrate.com/news/scripts-and-plugins/">Extensions</a>
                                            <a className="subitem" href="https://www.productioncrate.com/archives/misc/crates-toolbox-v1-0-2-04">AE Toolbox Extension</a>
                                            <a className="subitem" href="https://www.productioncrate.com/search/#!/soundscrate&amp;ss=tab">AE Sounds Extension</a>
                                            <a className="subitem" href="https://www.productioncrate.com/news/scripts-and-plugins/">AE Extension Archives</a>
                                            
                                            <a className="subitem title" href="https://www.productioncrate.com/after-effects-templates.html">Templates</a>
                                            <a className="subitem" href="https://www.productioncrate.com/brand-packages-ae-templates.html">Brand Packages</a>
                                            <a className="subitem" href="https://www.productioncrate.com/titles-intros-ae-templates.html">Titles &amp; Intros</a>
                                            <a className="subitem" href="https://www.productioncrate.com/add-ons-elements.html">Add Ons / Elements</a>
                                            <a className="subitem" href="https://www.productioncrate.com/ae-misc-templates.html">MISC</a>
                                        </div>
                                    </div>
                                    <a className="item title" href="https://www.productioncrate.com/news/">News &amp; Updates</a>
                                    <a className="item" href="https://www.productioncrate.com/news/blog/">Blog</a>
                                    <a className="item" href="https://www.productioncrate.com/news/content/">Latest Content</a>
                                    <a className="item" href="https://www.productioncrate.com/news/community/">Community</a>
                                    <a className="item" href="https://www.productioncrate.com/news/features/">Features</a>
                                    <div className="item with-submenu">
                                        <a href="https://www.youtube.com/productioncrate" target="_blank" rel="noopener">Share &amp; Subscribe +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://www.facebook.com/groups/ProductionCrate/" target="_blank" rel="noopener">Facebook Group</a>
                                            <a className="subitem" href="https://www.youtube.com/user/FootageCrate?sub_confirmation=1" target="_blank" rel="noopener">YouTube</a>
                                            <a className="subitem" href="https://www.instagram.com/productioncrate" target="_blank" rel="noopener">Instagram</a>
                                            <a className="subitem" href="https://twitter.com/productioncrate" target="_blank" rel="noopener">Twitter</a>
                                        </div>
                                    </div>
                                
                                
                                
                                    <a className="item title" href="https://www.productioncrate.com/support/index.html">Help &amp; Support</a>
                                    <a className="item" href="https://www.productioncrate.com/questions-answers.html">FAQ</a>
                                    <a className="item" href="https://www.productioncrate.com/content-queue.html">User Requests</a>
                                    <a className="item" href="https://www.productioncrate.com/terms.html">Terms &amp; Use License</a>
                                    <a className="item" href="https://www.productioncrate.com/support/how-to/how-to-add-vfx-to-videos.html">How to add VFX to videos</a>
                                    <a className="item" href="https://www.productioncrate.com/articles/free-software-for-vfx.html"><span>Free VFX editing software options</span></a>
                                    <a className="item" href="mailto:support@productioncrate.com">Contact Us</a>
                                    <div className="item with-submenu">
                                        <a href="https://member.productioncrate.com/">Members +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href={`${baseHref}/member`}>Login</a>
                                            <a className="subitem" href={`${baseHref}/register`}>Register</a>
                                            <a className="subitem" href={`${baseHref}/member`}>Member Page</a>
                                            <a className="subitem" href={`${baseHref}/register`}>Upgrade Account with PayPal</a>
                                            <a className="subitem" href={`${baseHref}/register`}>Upgrade Account with Stripe</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-col">
                                    <a className="item title" href="https://contests.productioncrate.com/">VFX Contests</a>
                                    <a className="item" href="https://contests.productioncrate.com/"><span>This Month's Contest</span></a>
                                    <a className="item" href="https://contests.productioncrate.com/staff-picks-1.html"><span>Staff Selected Video Entries</span></a>
                                    <a className="item" href="https://contests.productioncrate.com/hall-of-fame.html"><span>Hall of Fame</span></a>
                                
                                
                                
                                
                                    <a className="item title" href="https://learn.productioncrate.com/tutorials.html">Tutorials</a>
                                    <a className="item" href="https://learn.productioncrate.com/after-effects-tutorials.html"><span>After Effects</span></a>
                                    <a className="item" href="https://learn.productioncrate.com/hitfilm-tutorials.html"><span>HitFilm</span></a>
                                    <a className="item" href="https://learn.productioncrate.com/final-cut-tutorials.html"><span>Final Cut Pro</span></a>
                                    <a className="item" href="https://learn.productioncrate.com/adobe-premiere-tutorials.html"><span>Adobe Premiere</span></a>
                                    <a className="item" href="https://learn.productioncrate.com/imovie-tutorials.html"><span>iMovie</span></a>
                                    <a className="item" href="https://learn.productioncrate.com/davinci-resolve-lite-tutorials.html"><span>DaVinci Resolve</span></a>
                                    <a className="item" href="https://learn.productioncrate.com/sony-vegas-tutorials.html"><span>Sony Vegas</span></a>
                                    <a className="item" href="https://learn.productioncrate.com/cinema-4d-tutorials.html"><span>Cinema 4D</span></a>
                                    <a className="item" href="https://learn.productioncrate.com/3d-general-tutorials.html"><span>3D General</span></a>
                                
                                
                                
                                
                                    <a className="item title" href="https://member.productioncrate.com/index.php#ProductionCrate_Partners" >Affiliates</a>
                                    <a className="item" href="https://member.productioncrate.com/index.php#ProductionCrate_Partners"><span>Earn with ProductionCrate</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="submenu with-heading">
                            <div className="heading">
                                <a className="title" href="https://vfx.productioncrate.com/">VFX & Motion Elements</a> <span className="glyphicon glyphicon-down"></span>
                            </div>
                            <div className="dropdown">
                                <div className="head">FootageCrate</div>
                                <div className="menu-col">
                                    <a className="item title" href="https://vfx.productioncrate.com/visual-effects.html">Visual Effects</a>
                                    <a className="item" href="https://vfx.productioncrate.com/magic-powers-categories.html">Magic Powers</a>
                                    <a className="item" href="https://vfx.productioncrate.com/muzzle-flash-categories.html">Muzzle Flashes</a>
                                    <a className="item" href="https://vfx.productioncrate.com/toon-anime-categories.html">Toon/Anime FX</a>
                                    <a className="item" href="https://vfx.productioncrate.com/explosion-vfx-categories.html">Explosions</a>
                                    <a className="item" href="https://vfx.productioncrate.com/fire-and-sparks-vfx-categories.html">Fire &amp; Sparks</a>
                                    <a className="item" href="https://vfx.productioncrate.com/dust-smoke-categories.html">Dust &amp; Smoke</a>
                                    <a className="item" href="https://vfx.productioncrate.com/vehicle-categories.html">Vehicles</a>
                                    <a className="item" href="https://vfx.productioncrate.com/scifi-vfx-categories.html">Sci-Fi</a>
                                    <a className="item" href="https://vfx.productioncrate.com/debris-categories.html">Debris</a>
                                    <div className="item with-submenu">
                                        <a href="https://vfx.productioncrate.com/extras-categories.html">Extras +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://vfx.productioncrate.com/zombies-categories.html">Zombies</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/swat-henchmen.html">Swat/Henchmen</a>
                                        </div>
                                    </div>
                                    <div className="item with-submenu">
                                        <a href="https://vfx.productioncrate.com/action-video-effects-categories.html">Action &amp; Horror +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://vfx.productioncrate.com/missiles.html">Missiles</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/blood.html">Blood Impacts</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/3d-action.html">3D Action</a>
                                        </div>
                                    </div>
                                    <a className="item" href="https://vfx.productioncrate.com/liquids-ice-categories.html">Liquids/Ice</a>
                                    <a className="item" href="https://vfx.productioncrate.com/natural-elements-categories.html">Natural Elements</a>
                                    <a className="item" href="https://vfx.productioncrate.com/animals-creatures-categories.html">Animals/Creatures</a>
                                    <a className="item" href="https://vfx.productioncrate.com/plants-flowers.html">Plants &amp; Flowers</a>
                                    <a className="item" href="https://vfx.productioncrate.com/vfx-training.html">VFX Training</a>
                                </div>                       
                                <div className="menu-col">
                                    <a className="item title" href="https://vfx.productioncrate.com/mograph-editing.html">Mograph &amp; Editing</a>
                                    <a className="item" href="https://vfx.productioncrate.com/transition-categories.html">Transitions</a>
                                    <div className="item with-submenu">
                                        <a href="https://vfx.productioncrate.com/video-typekit-categories.html">Typography Kits +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://vfx.productioncrate.com/infected-typography.html">Infected Typography</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/neon-typography.html">Neon Typography</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/inferno-typography.html">Inferno Typography</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/meltdown-typography.html">Meltdown Typography</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/balloon-typography.html">Balloon Typography</a>
                                        </div>
                                    </div>
                                    <a className="item" href="https://vfx.productioncrate.com/backgrounds-categories.html">Backgrounds</a>
                                    <a className="item" href="https://vfx.productioncrate.com/accents-elements-categories.html">Elements/Accents</a>
                                    <a className="item" href="https://vfx.productioncrate.com/buttons-categories.html">Buttons &amp; Icons</a>
                                    <a className="item" href="https://vfx.productioncrate.com/technology.html">Technology</a>
                                    <a className="item" href="https://vfx.productioncrate.com/lower-thirds.html">Lower Thirds</a>
                                    <a className="item" href="https://vfx.productioncrate.com/lens-flares-bokeh-categories.html">Lens Flares/Bokeh</a>
                                    <a className="item" href="https://vfx.productioncrate.com/fluid-color.html">Fluid Color</a>
                                    <a className="item" href="https://vfx.productioncrate.com/youtube-categories.html">YouTube</a>
                                    <a className="item" href="https://vfx.productioncrate.com/textures-and-overlay-filters-categories.html">Textures &amp; Overlay Filters</a>
                                    <div className="item with-submenu">
                                        <a href="https://vfx.productioncrate.com/holiday-categories.html">Holiday +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://vfx.productioncrate.com/halloween.html">Halloween</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/christmas.html">Christmas</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/valentines-day.html">Valentines Day</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/new-years.html">New Years</a>
                                            <a className="subitem" href="https://vfx.productioncrate.com/thanksgiving.html">Thanksgiving</a>
                                        </div>
                                    </div>
                                    <a className="item" href="https://vfx.productioncrate.com/medical.html">Medical</a>
                                    <a className="item" href="https://vfx.productioncrate.com/sports.html">Sports</a>
                                    <a className="item" href="https://vfx.productioncrate.com/hand-gestures-categories.html">Hand Gestures</a>
                                    <a className="item" href="https://vfx.productioncrate.com/aerial.html">Aerial</a> 
                                    <div className="item with-submenu">
                                        <a href="https://www.productioncrate.com/news/scripts-and-plugins/">After Effects +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem title" href="https://www.productioncrate.com/news/scripts-and-plugins/">Extensions</a>
                                            <a className="subitem" href="https://www.productioncrate.com/news/scripts-and-plugins/">Scripts &amp; Plugins</a>
                                            
                                            <a className="subitem title" href="https://www.productioncrate.com/after-effects-templates.html">Templates</a>
                                            <a className="subitem" href="https://www.productioncrate.com/brand-packages-ae-templates.html">Brand Packages</a>
                                            <a className="subitem" href="https://www.productioncrate.com/titles-intros-ae-templates.html">Titles &amp; Intros</a>
                                            <a className="subitem" href="https://www.productioncrate.com/add-ons-elements.html">Add Ons / Elements</a>
                                            <a className="subitem" href="https://www.productioncrate.com/ae-misc-templates.html">MISC</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="submenu with-heading">
                            <div className="heading">
                                <a href="https://sfx.productioncrate.com/" className="title">SFX & Music</a> <span className="glyphicon glyphicon-down"></span>
                            </div>
                            <div className="dropdown">
                                <div className="head">SoundCrate</div>
                                <div className="menu-col">
                                    <a className="item title" href="https://sfx.productioncrate.com/sound-fx-categories.html">Sound Effects</a>
                                    <a className="item" href="https://sfx.productioncrate.com/electricity.html">Electricity</a>
                                    <a className="item" href="https://sfx.productioncrate.com/fighting.html">Fighting</a>
                                    <a className="item" href="https://sfx.productioncrate.com/lock-n-load.html">Lock and Load</a>
                                    <a className="item" href="https://sfx.productioncrate.com/weapons-other-categories.html">Weapons - Other</a>
                                    <a className="item" href="https://sfx.productioncrate.com/gun-sfx-categories.html">Gun Related</a>
                                    <a className="item" href="https://sfx.productioncrate.com/explosions-bangs-categories.html">Explosions &amp; Bangs</a>
                                    <a className="item" href="https://sfx.productioncrate.com/crashes-debris.html">Crashes/Debris</a>
                                    <a className="item" href="https://sfx.productioncrate.com/impacts.html">Impacts</a>
                                    <a className="item" href="https://sfx.productioncrate.com/blades-and-metal.html">Blades and Metal</a>
                                    <a className="item" href="https://sfx.productioncrate.com/magical.html">Magical</a>
                                    <a className="item" href="https://sfx.productioncrate.com/scifi-categories.html">SciFi SFX</a>
                                    <a className="item" href="https://sfx.productioncrate.com/mechanical.html">Mechanical</a>
                                    <a className="item" href="https://sfx.productioncrate.com/vehicles-categories.html">Vehicles</a>
                                    <a className="item" href="https://sfx.productioncrate.com/electronics.html">Electronics</a>
                                    <a className="item" href="https://sfx.productioncrate.com/movement.html">Movement</a>
                                    <a className="item" href="https://sfx.productioncrate.com/animals.html">Animals</a>
                                    <a className="item" href="https://sfx.productioncrate.com/people-voices-categories.html">People &amp; Voices</a>
                                    <a className="item" href="https://sfx.productioncrate.com/liquids.html">Liquids</a>
                                    <a className="item" href="https://sfx.productioncrate.com/organic.html">Organic</a>
                                    <a className="item" href="https://sfx.productioncrate.com/natural-elements.html">Natural Elements</a>
                                    <a className="item" href="https://sfx.productioncrate.com/nature-and-ambient.html">Ambient/Background</a>
                                    <a className="item" href="https://sfx.productioncrate.com/domestic-categories.html">Domestic</a>
                                    <a className="item" href="https://sfx.productioncrate.com/sports.html">Sports</a>
                                </div>
                                <div className="menu-col">
                                    <a className="item title" href="https://sfx.productioncrate.com/sound-design-categories.html">Sound Design</a>
                                    <a className="item" href="https://sfx.productioncrate.com/noir-classNameic-dramatic.html">Noir/classNameic</a>
                                    <a className="item" href="https://sfx.productioncrate.com/bass-drops.html">Bass Drops</a>
                                    <a className="item" href="https://sfx.productioncrate.com/cinematic-hits.html">Cinematic Hits</a>
                                    <a className="item" href="https://sfx.productioncrate.com/epic-sounds.html">Epic Sounds</a>
                                    <a className="item" href="https://sfx.productioncrate.com/anticipation.html">Anticipation</a>
                                    <a className="item" href="https://sfx.productioncrate.com/horror-sound-categories.html">Horror</a>
                                    <a className="item" href="https://sfx.productioncrate.com/funny-sound-effects-categories.html">Funny Sounds</a>
                                    <a className="item" href="https://sfx.productioncrate.com/video-games.html">Video Games</a>
                                    <a className="item" href="https://sfx.productioncrate.com/anime-toon.html">Anime/Toon</a>
                                    <a className="item" href="https://sfx.productioncrate.com/instruments.html">Instruments</a>
                                    <a className="item" href="https://sfx.productioncrate.com/dramatic.html">Sad/Dramatic</a>
                                    <a className="item" href="https://sfx.productioncrate.com/happy-positive.html">Happy/Positive</a>
                                    <a className="item" href="https://sfx.productioncrate.com/motion-graphics.html">Mograph Sounds</a>
                                
                                    <a className="item title" href="https://sfx.productioncrate.com/idents-categories.html">Idents</a>
                                    <a className="item" href="https://sfx.productioncrate.com/search.html#query=Corporate&amp;type=idents">Corporate</a>
                                    <a className="item" href="https://sfx.productioncrate.com/search.html#query=Holiday+Idents&amp;type=idents">Holiday Idents</a>
                                    
                                    <a className="item" href="https://sfx.productioncrate.com/search.html#query=Logo+Jingles&amp;type=idents">Logos &amp; Jingles</a>
                                    
                                    <a className="item" href="https://sfx.productioncrate.com/search.html#query=News+Jingle&amp;type=idents">News Jingles</a>
                                </div>
                                <div className="menu-col">
                                    <a className="item title" href="https://sfx.productioncrate.com/royalty-free-music-categories.html">Royalty Free Music</a>
                                    <a className="item" href="https://sfx.productioncrate.com/thriller.html">Thriller</a>
                                    <a className="item" href="https://sfx.productioncrate.com/suspense.html">Suspense &amp; Horror</a>
                                    <a className="item" href="https://sfx.productioncrate.com/drama.html">Drama</a>
                                    <a className="item" href="https://sfx.productioncrate.com/fantasy.html">Fantasy</a>
                                    <a className="item" href="https://sfx.productioncrate.com/western.html">Western</a>
                                    <a className="item" href="https://sfx.productioncrate.com/scifi-music.html">SciFi</a>
                                    <a className="item" href="https://sfx.productioncrate.com/feel-good-music.html">Feel Good</a>
                                    <a className="item" href="https://sfx.productioncrate.com/funny-music.html">Funny</a>
                                    <a className="item" href="https://sfx.productioncrate.com/epic.html">Epic</a>
                                    <a className="item" href="https://sfx.productioncrate.com/noir-mystery.html">Noir/Mystery</a>
                                    <a className="item" href="https://sfx.productioncrate.com/jazz.html">Jazz</a>
                                    <a className="item" href="https://sfx.productioncrate.com/action.html">Action</a>
                                    <a className="item" href="https://sfx.productioncrate.com/animated.html">Children &amp; Animated</a>
                                    <a className="item" href="https://sfx.productioncrate.com/hip-hop.html">Hip Hop</a>
                                    <a className="item" href="https://sfx.productioncrate.com/electronic.html">Electronic</a>
                                    <a className="item" href="https://sfx.productioncrate.com/lofi.html">LoFi</a>
                                    <a className="item" href="https://sfx.productioncrate.com/rock.html">Rock</a>
                                    <a className="item" href="https://sfx.productioncrate.com/dance-groove.html">Dance &amp; Groove</a>
                                    <a className="item" href="https://sfx.productioncrate.com/disco.html">Disco</a>
                                    <a className="item" href="https://sfx.productioncrate.com/atmospheric.html">Atmospheric / Experimental</a>
                                    <a className="item" href="https://sfx.productioncrate.com/world-folk.html">World &amp; Folk</a>
                                    <a className="item" href="https://sfx.productioncrate.com/corporate.html">Corporate</a>
                                    <a className="item" href="https://sfx.productioncrate.com/game-tracks.html">Game Tracks</a>
                                </div>
                            </div>
                        </div>
                        <div className="submenu with-heading">
                            <div className="heading">
                                <a className="title" href="https://graphics.productioncrate.com/">Graphics</a> <span className="glyphicon glyphicon-down"></span>
                            </div>
                            <div className="dropdown">
                                <div className="head">GraphicsCrate</div>
                                <div className="menu-col">
                                    <a className="item title" href="https://graphics.productioncrate.com/effects.html">Still Effects</a>
                                    <a className="item" href="https://graphics.productioncrate.com/building-damage.html">Building Damage</a>
                                    <a className="item" href="https://graphics.productioncrate.com/bullet-holes.html">Bullet Holes</a>
                                    <a className="item" href="https://graphics.productioncrate.com/cracks.html">Cracks</a>
                                    <a className="item" href="https://graphics.productioncrate.com/scorch-marks.html">Scorch Marks</a>
                                    <a className="item" href="https://graphics.productioncrate.com/smashed-glass.html">Smashed Glass</a>
                                    <a className="item" href="https://graphics.productioncrate.com/textures-grunge-lens-and-glass.html">Lens and Glass</a>
                                    <a className="item" href="https://graphics.productioncrate.com/effects-blood-splatter.html">Blood Splatter</a>
                                    <a className="item" href="https://graphics.productioncrate.com/effects-torn-fabric.html">Torn Fabric</a>
                                
                                
                                    <a className="item title" href="https://graphics.productioncrate.com/film-and-video.html">Film and Video</a>
                                    <div className="item with-submenu">
                                        <a href="https://graphics.productioncrate.com/aspect-ratio-bars.html">Aspect Ratio Bars +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://graphics.productioncrate.com/720.html">720</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/1080.html">1080</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/2k.html">2K</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/4k.html">4K</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/8k.html">8K</a>
                                        </div>
                                    </div>
                                    <a className="item" href="https://graphics.productioncrate.com/film-and-video-gobos.html">Gobos</a>
                                    <a className="item" href="https://graphics.productioncrate.com/film-and-video-compositional-grids.html">Compositional Grids</a>
                                    
                                
                                
                                    <a className="item title" href="https://graphics.productioncrate.com/textures.html">Textures</a>
                                    <a className="item" href="https://graphics.productioncrate.com/puddle-maps.html">Puddle Maps</a>
                                    <div className="item with-submenu">
                                        <a href="https://graphics.productioncrate.com/dirt-grunge.html">Grunge Surfaces +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://graphics.productioncrate.com/concrete.html">Concrete</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/rust.html">Rust</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/rock.html">Rock</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/dirt.html">Dirt</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/wood.html">Wood</a>
                                        </div>
                                    </div>
                                    <a className="item" href="https://graphics.productioncrate.com/textures-roads.html">Roads</a>
                                </div>
                                <div className="menu-col">
                                    <a className="item title" href="https://graphics.productioncrate.com/set-extensions.html">Set Extensions</a>
                                    <a className="item" href="https://graphics.productioncrate.com/explosions-and-smoke.html">Explosions &amp; Smoke</a>
                                    <a className="item" href="https://graphics.productioncrate.com/buildings.html">Buildings</a>
                                    <div className="item with-submenu">
                                        <a href="https://graphics.productioncrate.com/landscapes.html">Landscapes +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://graphics.productioncrate.com/landscapes-beach.html">Beach</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/mountains.html">Mountains</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/landscapes-rainforest.html">Rainforest</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/landscapes-countryside.html">Countryside</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/landscapes-alien.html">Alien</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/landscapes-desert.html">Desert</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/landscapes-snow.html">Snow</a>
                                        </div>
                                    </div>
                                    <a className="item" href="https://graphics.productioncrate.com/set-extensions-clouds.html">Clouds</a>
                                    <div className="item with-submenu">
                                        <a href="https://graphics.productioncrate.com/space.html">Space +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://graphics.productioncrate.com/space-stellar-objects.html">Stellar Objects</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/space-planets.html">Planets</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/space-planet-rings-from-surface.html">Planet Rings From Surface</a>  
                                        </div>
                                    </div>
                                    <a className="item" href="https://graphics.productioncrate.com/set-extensions-destruction.html">Destruction</a>
                                    <a className="item" href="https://graphics.productioncrate.com/set-extensions-apocalypse.html">Apocalypse</a>
                                    <a className="item" href="https://graphics.productioncrate.com/set-extensions-ancient.html">Ancient</a>
                                    <a className="item" href="https://graphics.productioncrate.com/plants.html">Plants</a>
                                    <a className="item" href="https://graphics.productioncrate.com/set-extensions-skies.html">Skies</a>
                                    <a className="item" href="https://graphics.productioncrate.com/signs.html">Signs</a>
                                    <a className="item" href="https://graphics.productioncrate.com/security-structures.html">Security Structures</a>
                                    <a className="item" href="https://graphics.productioncrate.com/set-extensions-people-and-extras-categories.html">People and Extras</a>
                                    <a className="item" href="https://graphics.productioncrate.com/vehicles-categories.html">Vehicles</a>
                                    <a className="item" href="https://graphics.productioncrate.com/walls-fencing.html">Walls &amp; Fencing</a>
                                    <a className="item" href="https://graphics.productioncrate.com/destruction-cables-and-wires.html">Cables and Wires</a>
                                
                                    <a className="item title" href="https://graphics.productioncrate.com/print-and-design.html">Print &amp; Design</a>
                                    <a className="item" href="https://graphics.productioncrate.com/design-balloon-font.html">Balloon Font</a>
                                    <a className="item" href="https://graphics.productioncrate.com/print-design-8bit-pixels.html">8bit Pixels</a>
                                    <div className="item with-submenu">
                                        <a href="https://graphics.productioncrate.com/textures-backgrounds.html">Backgrounds +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://graphics.productioncrate.com/textures-clean-backdrops.html">Clean Backdrops</a>
                                            <a className="subitem" href="https://graphics.productioncrate.com/comic-halftone-backgrounds.html">Comic &amp; Pop Art</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="submenu with-heading">
                            <div className="heading">
                                <a className="title" href="https://www.productioncrate.com/3D/">3D & AR</a> <span className="glyphicon glyphicon-down"></span>
                            </div>
                            <div className="dropdown">
                                <div className="head">RenderCrate</div>
                                <div className="menu-col">
                                    <a className="item title" href="https://www.productioncrate.com/3D/models.html">Models</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/urban.html">Urban</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/weapons.html">Weapons</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/vehicles.html">Vehicles</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/money.html">Money &amp; Treasure</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/space.html">Space</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/ancient.html">Ancient</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/sports.html">Sports</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/domestic.html">Domestic</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/food.html">Food</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/characters.html">Characters</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/debris.html">Debris</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/gore.html">Gore</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/apocalypse.html">Apocalypse</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/atmospheric-categories.html">Atmospheric</a>
                                    <div className="item with-submenu">
                                        <a href="https://www.productioncrate.com/3D/nature.html">Nature +</a>
                                        <div className="dropdown-submenu">
                                            <a className="subitem" href="https://www.productioncrate.com/3D/nature-vegetation.html">Vegetation</a>
                                            <a className="subitem" href="https://www.productioncrate.com/3D/nature-landscape.html">Landscape</a>
                                            <a className="subitem" href="https://www.productioncrate.com/3D/nature-terrain.html">Terrain</a>
                                        </div>
                                    </div>
                                    <a className="item" href="https://www.productioncrate.com/3D/anatomical-medical.html">Anatomical &amp; Medical</a>
                                </div>
                                <div className="menu-col">
                                    <a className="item title" href="https://www.productioncrate.com/3D/materials.html">Materials</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/architecture.html">Architecture</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/terrain.html">Terrain</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/rock.html">Rock</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/fabric.html">Fabric</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/metal.html">Metal</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/materials-sand.html">Sand</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/materials-rust.html">Rust</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/materials-dirt.html">Dirt</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/materials-roads.html">Roads</a>
                                    
                                    <a className="item title" href="https://www.productioncrate.com/3D/environments.html">Environments</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/skies.html">Skies</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/hdr-studio.html">Studio</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/hdr-aerial.html">Aerial</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/hdr-landscape.html">Landscape</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/hdr-space.html">Space</a>
                                    <a className="item" href="https://www.productioncrate.com/3D/hdr-ocean.html">Ocean</a>
                                </div>
                            </div>
                        </div>
                        <div className="submenu with-heading">
                            <div className="heading">
                                <a className="title" href="https://news.productioncrate.com/scripts-and-plugins/">Plugins</a> <span className="glyphicon glyphicon-down"></span>
                            </div>
                            <div className="dropdown">
                                <div className="head">Plugins & Extensions</div>
                                <div className="menu-col">
                                    <a className="item" href="https://www.productioncrate.com/portal">Portal Installer</a>
                                    <a className="item" href="https://www.productioncrate.com/laforge">LaForge</a>
                                    <a className="item" href="https://www.productioncrate.com/plugins/rendercrate-blender">RenderCrate Blender</a>
                                    <a className="item" href="https://news.productioncrate.com/soundscrateextension/">SoundsCrate</a>
                                    <a className="item" href="https://news.productioncrate.com/download-free-light-wrap-plugin-for-after-effects/">Light Wrap</a>
                                    <a className="item" href="https://news.productioncrate.com/download-free-godrays-plugin-for-after-effects/">Godrays</a>
                                    <a className="item" href="https://news.productioncrate.com/make-holograms-in-after-effects-free-script-download/">Hologram Generator</a>
                                    <a className="item" href="https://www.productioncrate.com/archives/misc/crates-toolbox-v1-0-2-04">Crate's Toolbox</a>
                                    <a className="item" href="https://news.productioncrate.com/texture-flow-free-ae-script/">TextureFlow</a>
                                    <a className="item" href="https://news.productioncrate.com/download-productioncrates-free-extension/">Connect</a>
                                    <a className="item" href="https://news.productioncrate.com/download-the-powerful-lower-thirds-script-for-after-effects/">Lower Third Builder</a>
                                    <a className="item" href="https://news.productioncrate.com/crates-free-text-animator-script-for-after-effects-download/">Text Animator</a>
                                    <a className="item" href="https://www.productioncrate.com/plugins/rendercrate">RenderCrate AE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="controls">

                        {!premiumAssetsPolicy && user &&
                        <>
                            
                            {user.team.last_paid_subscription &&
                            <div className="item show-logged-free accent" style={{cursor: 'pointer'}} onClick={() => reactivateUser(user.team.last_paid_subscription.provider, user.team.last_paid_subscription.plan_id)}>
                                Restore Subscription
                            </div>
                            }
                            {!user.team.last_paid_subscription &&
                            <>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade`}>
                                    <span className="glyphicon glyphicon-credit-card"></span>
                                </a>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade/paypal`}>
                                    <span className="glyphicon glyphicon-paypal"></span>
                                </a>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade`}>
                                    <span className="glyphicon glyphicon-lock"></span>
                                </a>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade`}>
                                    Upgrade to Pro
                                </a>
                            </>
                            }
                        </>
                        }
                        {!user &&
                        <>
                            <a className="item show-logged-out" href={`${baseHref}/member`}>
                                Login
                            </a>
                            <a className="item show-logged-out accent" href={`${baseHref}/register`}>
                                Register
                            </a>
                        </>
                        }
                        {false && 
                        <div className="item hamburger-menu show-logged-out show-logged-free show-logged-pro">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </div>
                        }
                    </div>
                </div>
            </div>
            }
            {user && user.email_verified === false && 
            <div className="banner">
                <div className='content' onClick={() => document.location.href='/member/email-verify'}>
                Action Required: Your email address has not been verified yet. Click <a href='/member/email-verify'>here</a> to resend the verification email
                </div>
            </div>
            }
            {loading &&
            <div style={{background: 'rgba(0,0,0,0.9)', width: '100vw', height: '100vh', top: '0', left: '0', position: 'fixed', zIndex: '9', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Spinner label="Reactivating subscription..." />
            </div>
            }
        </div>
    );
};

export default forwardRef(Header);

