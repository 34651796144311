import './Faq.scss';


const Faq = ({donateUncheckHandle}) => {

    const freeClickHandle = () => {
        if(donateUncheckHandle){
            donateUncheckHandle();
        } else{
            console.error("donateUncheckHandle is not defined");
        }
    }

    return (
        <div className="faq">
            <div className="content m-h-a">
                <div className="title">
                    FAQs
                </div>
                <div className="faq-list">
                    <div className="column">
                        <p>
                            <strong>What software is this compatible with?</strong>
                            <span className="text-gray text-small">
                                We provide several download formats for each asset to ensure maximum compatibility with all editing suites! You can use whatever software you are most comfortable with, or already have installed.<br />
                                <br />
                                For a list of free video editing software options <a href="https://www.productioncrate.com/articles/free-software-for-vfx.html" target="_blank">click here</a>.
                            </span>
                        </p>
                        <p>
                            <strong>How much content do you host?</strong>
                            <span className="text-gray text-small">
                                With our Pro account, you can access 18,000+ original premium-grade production assets. We always strive to provide you with the widest range of the best quality content, and upload new items in bulk each week.
                            </span>
                        </p>
                        <p>
                            <strong>Can I try ProductionCrate before I buy?</strong>
                            <span className="text-gray text-small">
                                Yes! Just <a onClick={freeClickHandle}>sign up for a free account to start downloading</a>. Donating help us create more top quality content, but contributing is always
                                optional.<br />
                                <br />
                                With the free plan you will have access to thousands of free items from our basic / 2K resolution libraries, and be limited to 5 downloads per day.<br />
                                <br />
                                All items without a star next to them are free to download. Items with a star are exclusively Pro. No billing info is required to sign up and start downloading with the free option.
                            </span>
                        </p>
                        <p>
                            <strong>Who is this content created for?</strong>
                            <span className="text-gray text-small">
                                You! Our assets are used by everyone, including famous Youtubers and top trending video producers, big blockbuster production companies, 3D game developers, DJs, CGI and VFX artists, entrepreneurs, and many more!<br />
                                At ProductionCrate you can quickly find whatever you are looking for from our massive selection of time-saving assets, which are both easy to use, and professional quality regardless of your skill level!
                            </span>
                        </p>
                        <p>
                            <strong>Can I use these assets in commercial projects?</strong>
                            <span className="text-gray text-small">Totally! Our use license grants you permanent use of our assets even if you no longer wish to subscribe.</span>
                        </p>
                    </div>

                    <div className="column">
                        <p>
                            <strong>What Payment Methods do you accept?</strong>
                            <span className="text-gray text-small">
                                We accept secure online credit cards, and PayPal. For users of our ProductionCrate AR IOS app we also accept Apple Pay. If you need to use another payment provider just <a href="mailto:support@productioncrate.com">reach out to support team</a> so we can accommodate you.
                                <br/><br/>
                                Note: You don't need a credit card to sign up for the basic account unless donating. Go ahead and <a href="https://www.productioncrate.com/register/#plan=free">try ProductionCrate</a> with no strings attached!
                            </span>
                        </p>
                        <p><strong>Is ProductionCrate sold as a subscription?</strong><br/><span className="text-gray text-small">Yes. You will be billed monthly or annually for the plan you purchase.</span></p>
                        <p><strong>Can I cancel anytime?</strong><br/><span className="text-gray text-small">Of course! There's no commitment: you can cancel your ProductionCrate subscription anytime from the account settings page, or by reaching out to <a href="mailto:support@productioncrate.com">support</a>.</span></p>
                        <p><strong>How can I get a refund?</strong><br/><span className="text-gray text-small">You can cancel your subscription at any time, but we cannot issue refunds for partial use of service or the amount of time left in your subscription.</span></p>
                        <p><strong>Are there larger plans?</strong><br/><span className="text-gray text-small">Absolutely. Just <a href="mailto:support@productioncrate.com">shoot us a message</a> and we'll build a custom plan that fits your business best.</span></p>
                    </div>


                    <div className="column wide">
                        <p>
                            <b>Have any other questions?</b> We're always here to answer them, so don't hesitate to <a href="mailto:support@productioncrate.com" >contact us</a> with whatever it is you have on your mind!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;

