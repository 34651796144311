import axios from 'axios'
import { getCookie, deleteCookie} from './utils'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://rest.productioncrate.com/', //'http://crate-core.com/', //'http://crate-core.test/', //'https://rest-qa.productioncrate.com/', //'http://crate-core.test/',
  withCredentials: false,
  mode: 'no-cors'
})

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
instance.defaults.headers.common['Content-Type'] = 'application/json'
//instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//instance.defaults.mode = 'no-cors';

// Add JWT token if present in local storage
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token') || getCookie('newLogin');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
});



if(!window.requestStack){
  window.requestStack = [];
}

instance.interceptors.response.use(response => {
  return response;
}, async error => {
 const originalRequest = error.config;
 if (error.response.status === 401) {


  if(originalRequest.url.includes('login') || originalRequest.url.includes('register')){
    if(error.response.data) {
      //console.log("401 error", error.response.data);
      return Promise.reject(error.response.data);
    } else {
      //console.log("401 error", {msg: "no error message from api"});
      return Promise.reject("no error message from api");
    }
  }
  if( originalRequest.url.includes('refresh') ){
    
    return false;
  }

  if(originalRequest._retry){
    //place your reentry code
    return Promise.reject(error.response.data?error.response.data:"no error message from api");
  }

  originalRequest._retry = true;

  let requestPromise = new Promise((resolve, reject) => {
    window.requestStack.push({
      url: originalRequest.url,
      request: () =>  instance(originalRequest), 
      promiseResolve: resolve,
      promiseReject: reject,
    });
  }).catch(e => {
    //console.log("Error while requesting ",originalRequest.url, e);
  });
  

  if(window.refreshToken && window.requestStack.length === 1){
    //console.log("refreshing token");
    window.refreshToken()
    .then((new_access_token) => {
      //console.log("NEW TOKEN", new_access_token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + new_access_token;
      window.requestStack.forEach(req => {
        //console.log("resending request", req.url);
        req.promiseResolve(req.request());
      });
    })
    .catch(e => {
      //console.log("error while refreshing token", e);
      //deleteCookie('newLogin_refresh', 'productioncrate.com');
      //localStorage.removeItem('refresh_token');
      console.log("couldn't refresh token...");
      return false;
    });
  }

  return await requestPromise;

 }else if( error.response.status === 422){
    if(error.response.data) {
      //console.log("401 error", error.response.data);
      return Promise.reject(error.response.data);
    } else {
      //console.log("401 error", {msg: "no error message from api"});
      return Promise.reject("no error message from api");
    }
 }else{
  return Promise.reject(error);
 }

});





export default instance
