import React, { createContext, useContext, useState, useEffect} from "react";
import { meFn, refreshTokenRequest } from '../lib/api';
import { getCookie, deleteCookie, setCookie, setPersistedValue, getPersistedValue } from '../lib/utils';
// create context
const UserContext = createContext();
  
const UserContextProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [userToken, setUserToken] = useState(false);
    const [refreshToken, setRefreshToken] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [focusMode, setFocusMode] = useState(false);

    useEffect(() => {
        
        const token = getPersistedValue('token', 'newLogin');

        //console.log("GOT THIS TOKEN", token);
        if(!token){
            //console.log("no token to set");
            setUserToken(null);
            setUser(null);
            setIsAuthenticating(false);
        } else {
            //console.log("we have a token to set");
            setUserToken(token);
            setIsAuthenticating(true);
        }

        const refresh_token = getPersistedValue('refresh_token', 'newLogin_refresh');

        if(!refresh_token){
            //console.log("no refresh token");
            setRefreshToken(null);
        } else {
            //console.log("we have a refresh token to set");
            setRefreshToken(refresh_token);
        }

        window.refreshToken = async () => {
            const refresh_token = getPersistedValue('refresh_token', 'newLogin_refresh');

            if(refresh_token){
                let data;
                try{
                    data = await refreshTokenRequest(refresh_token);
                    if(data && data.access_token){
                        console.debug("Persist token and refresh after refres-token request response");
                        setPersistedValue('token', 'newLogin', data.access_token);
                        setPersistedValue('refresh_token', 'newLogin_refresh', data.refresh_token);
    
                        setUserToken(data.access_token);
                        setRefreshToken(data.refresh_token);
                        
                        //console.log("we got a refresh!");
                        return data.access_token;
                    }
                }catch(e){
                    //console.log("error while refreshing token", e);
                }
                deleteCookie('newLogin_refresh');
                localStorage.removeItem('refresh_token');
                //console.log("maybe the refresh token was expired..");
                return false;
            }else{
                //console.log("no refresh token available...");
                return false;
                //await new Promise(resolve => setTimeout(resolve, 1000));
                //return await window.refreshToken();
            }
        };
    }, []);

    useEffect(() => {
        if(!userToken){
            return;
        }
        console.debug("use effect perssisting token");
        setPersistedValue('token', 'newLogin', userToken);
        //delay in case refreshToken is being stored...
        setTimeout(loadCurrentUser, 300);  
    }, [userToken]);

    useEffect(() => {
        if(!refreshToken){
            return;
        } 
        console.debug("use effect perssisting refresh token");
        setPersistedValue('refresh_token', 'newLogin_refresh', refreshToken);
    }, [refreshToken]);
    


    useEffect(() => {
        if(!user){
            return;
        }
        //console.log("USER", user);
        setIsAuthenticating(false);
        //Set a user creation tiemstamp as a cookie/localStorage
        if(user && user.created_at){
            let registrationTimestamp = new Date(user.created_at).getTime();
            localStorage.setItem('crateRegistration', `${registrationTimestamp}`);
            setCookie('crateRegistration', `${registrationTimestamp}`, 365);
        }
    }, [user]);

    const loadCurrentUser = async () => {
        setIsAuthenticating(true);
        try {
            let data = await meFn();
            data.premiumAssetsPolicy = false;
            data.isEnterprise = false;

            if(data.team && data.team.subscription && data.team.subscription.plan){
                let tempPlan = data.team.subscription.plan;
                let charges = 0;
                let usersOnTeam = 0;
                tempPlan.features.forEach(feat => {
                    if(feat.name === 'assets-download'){
                        charges =+ feat.charges;
                        if (feat.policy && feat.policy.name.toLowerCase().includes('free')) {
                        } else {
                            data.premiumAssetsPolicy = true;
                        }
                    }
                    if(feat.name === 'users-on-team'){
                        usersOnTeam += feat.charges;
                        if(usersOnTeam > 1){
                            data.isEnterprise = usersOnTeam;
                        }else{
                            data.isEnterprise = false;
                        }
                    }
                });
            }
            setUser(data);

        } catch(err) { 
            //console.log("User Authentication ERROR", err);
            setUserToken(null);
            setIsAuthenticating(false);
        };
    };

    const unloadUser = () => {
        //document.cookie = `newLogin=0;path=/;domain=productioncrate.com`;
        setUserToken(null);
        setRefreshToken(null);
        setPersistedValue('token', 'newLogin', null);
        setPersistedValue('refresh_token', 'newLogin_refresh', null);
        setUser(null);
    };

    return (
        // the Provider gives access to the context to its children
        <UserContext.Provider
          value={{
            user,
            userToken,
            isAuthenticating,
            setUser,
            setUserToken,
            setRefreshToken,
            reloadCurrentUser: loadCurrentUser,
            unloadUser,
            focusMode,
            setFocusMode,
          }}
        >
          {children}
        </UserContext.Provider>
      );
};

const useUserContext = () => useContext(UserContext);

export { useUserContext, UserContextProvider };

