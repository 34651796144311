import React, {useState, useEffect, useRef} from 'react';
import './Modal.scss';


const Modal = ({title, img, msg, className, cta, ctaCallback, ctaClose, ctaCloseCallback, ctaSize, ctaDisabled, overlayCallback, visible, style={}, showCloseIcon}) => {


    const handleCtaClick = () => {
        if (ctaCallback) {
            ctaCallback();
        }
    };

    const handleCtaCloseClick = () => {
        if (ctaCloseCallback) {
            ctaCloseCallback();
        }
    };

    const handleOverlayClick = (event) => {
        event.preventDefault();
        if(overlayCallback){
            overlayCallback();
        }
        //console.log("overlay click");
    };
    return (
        <div className={`modal_container` } >
            <div className={`overlay ${visible?'visible':''}`} onClick={(event) => handleOverlayClick(event)}></div>
            <div className={`modal ${visible?'visible':''}  ${className?className:''}`} style={style}>
                {showCloseIcon && <span className="glyphicon glyphicon-plus close" onClick={handleCtaCloseClick} ></span>}
                {title && <div className="modal_title">{title}</div>}
                {img && <img className="plan_img m-t-a m-b-a" src={img} width="120" height="120"></img>}
                {msg && <div className="msg m-b-32 m-t-32">{msg}</div>}
                {cta && <div className={`cta ${ctaSize?ctaSize:''} ${ctaDisabled?'disabled':''} m-t-36`} onClick={handleCtaClick}>{cta}</div>}
                {ctaClose && <div className="cta-close m-t-32" onClick={handleCtaCloseClick}>{ctaClose}</div>}
            </div>
        </div>
    );
};

export default Modal;

